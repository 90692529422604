@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@media screen and (max-width:480px) {
    .font-medium{
        margin-left: 36%;
        margin-top: 47px  ;
    }
   
}